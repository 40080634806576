<template>
  <div class="baseAchievement">
    <nf-breadcrumb
      :breadList="[{ name: breadCrumbName || '知识产权' }, { name: '基地成果' }]"
    ></nf-breadcrumb>
    <div id="mapWrap"></div>
  </div>
</template>

<script>
// import AMap from '@/utils/AMap.js';
import nfBreadcrumb from '@/component/breadcrumb/breadcrumb';
const sanya  = require("./sanya.json");
const lingshui  = require("./lingshui.json");
const ledong  = require("./ledong.json");

export default {
  name: 'baseAchievement',
  data() {
    return {
      startTime: null,
      pageName: "知识产权>基地成果(baseAchievement.vue)",
      breadCrumbName: '知识产权',
      map: null,
      resMap: null,
      resChildMap: null,
      zoom: 10, // 地图层级
      markerList: [], // 点集合
      markerChildList:[],//子点集合
      mapCenter: [109.605279, 18.499066],
      infoWindow: null, // 信息窗体
      childInfoWindow: null, // 子级信息窗体
      // 基地集合
      markerArr: [],
      geoJsonData: {
        '三亚市': sanya,
        '陵水县': lingshui,
        '乐东县': ledong,
      },
      geoJson: {},
      //子基地
      childMarkerList:[]
    };
  },
  components: {
    nfBreadcrumb
  },
  beforeCreate() {
    this.startTime = performance.now();
    let this_ = this;
    //1.页面停留时间
    window.addEventListener('beforeunload', function() {
      let endTime = performance.now();
      let stayTime = ((endTime - this_.startTime)/1000).toFixed(1);
      console.log("stayTime", stayTime);

      window.SZHN_FIRE_BEAR?.trackEvent({
        key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
        segmentation: {
          stayTime: stayTime,
        }
      })
    });
    //3.页面加载时间
    window.addEventListener('load', function() {
      let endTime = performance.now();
      let loadTime = ((endTime - this_.startTime)/1000).toFixed(1);
      console.log("loadTime", loadTime);
      window.SZHN_FIRE_BEAR?.trackEvent({
        key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
        segmentation: {
          loadTime: loadTime,
        }
      })
    });
  },
  beforeRouteEnter: (to, from, next) => {
    next((vm) => {
      if (from.name === 'nanFanManagement') {
        vm.breadCrumbName = '南繁管理'
      }
    });
  },
  methods: {
    trackNetworkRequest(url) {
      let requestStartTime = performance.now();
      let this_ = this;

      fetch(url)
          .then(response => {
            let requestEndTime = performance.now();
            let requestTime = ((requestEndTime - requestStartTime)/1000).toFixed(3);
            console.log("requestSuccessTime", requestTime);
            window.SZHN_FIRE_BEAR?.trackEvent({
              key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
              segmentation: {
                requestTime: requestTime,
                requestStatu: "success",
                requestResult: response.toString()
              }
            })
          })
          .catch(error=>{
            let requestEndTime = performance.now();
            let requestTime = ((requestEndTime - requestStartTime)/1000).toFixed(3);
            console.log("requestErrorTime", requestTime);

            window.SZHN_FIRE_BEAR?.trackEvent({
              key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
              segmentation: {
                requestTime: requestTime,
                requestStatu: "error",
                requestResult: error.toString()
              }
            })
          })
    },
    async getChildList(cityID){
      const api = this.$fetchApi.baseList.api;
      const data = await this.$fetchData.fetchPost({cityId:cityID,pageSize:"99999"}, api, 'json');
      if (data.code === '200' && data.result) {
        this.childMarkerList = data.result.rows;
        // this.total = data.result.total;
        this.childMarkerList.forEach((item, index) => {
          item.id=item.cityId;
          if(item.longitude!=""&&item.longitude!=null&&item.longitude!=undefined){
            // 设置经纬度
            const point = [item.longitude, item.latitude];
            // 创建点
            const markerChild = new this.resMap.Marker({
              position: point
            });

            // 点击显示信息窗体
            markerChild.on('mouseover', () => {
              // 设置中心点
              // this.map.setCenter(point);
              //  this.map.setZoom(13)
              //  console.log(this.getChildList(this.markerArr[index].id))
              // 信息窗体
              this.showChildInfoWindow(item, index);

            });
            // 添加到点集合
            this.markerChildList.push(markerChild);
          }

      });
      // 添加到地图中
      this.map.add(this.markerChildList);
      }
    },
    //移除点
    removeMarker(){
      console.log(this.markerChildList)
      this.map.remove(this.markerChildList);
      this.markerChildList = [];
    },
     // 显示信息窗体
    showChildInfoWindow(item, index) {
      // console.log(item)
      const point = [item.longitude, item.latitude];
      this.childInfoWindow = new this.resMap.InfoWindow({
        content: `
        <div style='padding: 2px; border-radius: 5px;'>
          <div style='font-weight:900; '>${item.baseName}</div>
        </div>
        `,
        offset: new this.resMap.Pixel(5, -25)
      });
      // console.log(this.map)
      this.childInfoWindow.open(this.map, point);
    },
    // 获取基地区域
    async getList() {
      const api = this.$fetchApi.baseArea.api;
      const data = await this.$fetchData.fetchPost({}, api, 'json');
      if (data.code === '200' && data.result) {
        data.result[0].lng = 109.508268;
        data.result[0].lat = 18.247872;
        data.result[1].lng = 109.173055;
        data.result[1].lat = 18.75026;
        data.result[2].lng = 110.037504;
        data.result[2].lat = 18.506048;
        this.markerArr = data.result;
        this.initAMap();
      }
    },

    // 初始化地图
    async initAMap() {
      this.resMap = window.AMap;
      this.map = new this.resMap.Map('mapWrap', {
        zoom: this.zoom, //初始化地图层级
        center: this.mapCenter //初始化地图中心点
      });
      // 添加点标记
      this.addMarker();
    },
    // 添加点
    addMarker() {
      this.markerArr.forEach((item, index) => {
        // 设置经纬度
        const point = [item.lng, item.lat];
        // 创建点
        const marker = new this.resMap.Marker({
          //  icon: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-' + (index + 1) + '.png',
          position: point
        });
        // 点击显示信息窗体
        marker.on('click', () => {
          // 设置中心点
          this.map.setCenter(point);
          this.map.setZoom(13);
          //  console.log(this.getChildList(this.markerArr[index].id))
          // 信息窗体
          this.showInfoWindow(item, index);
          this.map.remove(this.geoJson);
          this.geoJson = new this.resMap.GeoJSON({
            geoJSON: this.geoJsonData[item.name],   // GeoJSON对象
            getPolygon:  function(geojson, lnglats) {//还可以自定义getMarker和getPolyline
              return new AMap.Polygon({
                path: lnglats,
                fillOpacity: .5,
                strokeWeight: 1,
                strokeColor:'rgba(255,0,0,.5)',
                fillColor:'rgba(255,0,0,.2)'
              });
            }
          });

          this.map.add(this.geoJson);

          //添加点下面的子点
          this.removeMarker();
          this.getChildList(this.markerArr[index].id);
        });
        // 添加到点集合
        this.markerList.push(marker);
      });
      // 添加到地图中
      this.map.add(this.markerList);
    },
    // 显示信息窗体
    showInfoWindow(item, index) {
      const point = [item.lng, item.lat];
      this.infoWindow = new this.resMap.InfoWindow({
        content: `
        <div style='padding: 10px; border-radius: 5px;'>
          <div style='font-weight:900; padding-bottom: 5px'>${item.name}</div>
          <div style='color: #757575'>
            <span style='padding-right: 30px'>基地数量：${item.showCount}</span>
          </div>
          <div style='padding-top: 10px; color: #265ae1' onClick='enterBaseList(${index})'>进入该基地 >></div>
        </div>
        `,
        offset: new this.resMap.Pixel(15, -25)
      });
      this.infoWindow.open(this.map, point);
    },
    // 进入基地列表
    enterBaseList(index) {
      this.$router.push({
        path: '/baseList',
        query: this.markerArr[index]
      });
    }
  },
  created() {
    //2.用户浏览量
    window.SZHN_FIRE_BEAR?.trackEvent({
      key: this.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
      segmentation: {
        pageViews: 1,
      }
    })
    //4.网络请求状态
    this.trackNetworkRequest("https://nfggy.com");
    window.enterBaseList = this.enterBaseList;
    this.getList();
  }
};
</script>

<style lang="less" scoped>
#mapWrap {
  width: 100%;
  height: 700px;

}
.amap-icon{
  width: 25px!important;
  height: 34px!important;
}
</style>
